import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

mapboxgl.accessToken = 'pk.eyJ1IjoidHhhbWkiLCJhIjoiY2o5cHlqcHp1NW91ZTJ3cXRmNDFzcnpmaCJ9.6mMGa5Rv1wjwbsqCqfLgFg';

var map = new mapboxgl.Map({
  container: 'mapbox',
  style: 'mapbox://styles/txami/cjuvcvvy60f3k1foj5jm8dj9d',
  center: [2.933143673381551,41.72160734205613], //starting position
  zoom: 14 // starting zoom
});

var popup = new mapboxgl.Popup({ offset: 25 })
  .setText('Turissa Salut');

new mapboxgl.Marker()
  .setLngLat([
    2.933143673381551,
    41.72160734205613])
  .setPopup(popup)
  .addTo(map);
